var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lahan-side-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Status")]),(_vm.projectPurpose === 'carbon')?_c('div',{staticClass:"d-flex flex-row value"},[_c('span',{class:{
            'badge bg-warning':
                _vm.data.main_lahan.approve == 0 &&
                _vm.data.main_lahan.updated_gis.toLowerCase() == 'belum',
            'badge bg-info':
                _vm.data.main_lahan.approve == 0 &&
                _vm.data.main_lahan.updated_gis.toLowerCase() == 'sudah',
            'badge bg-primary':
                _vm.data.main_lahan.approve == 1 &&
                _vm.data.main_lahan.fc_complete_data != null,
            'badge bg-success': _vm.data.main_lahan.approve == 2,
            'badge bg-danger':
                _vm.data.main_lahan.approve == 3 ||
                (_vm.data.main_lahan.approve == 1 &&
                    _vm.data.main_lahan.fc_complete_data == null),
        }},[(
                _vm.data.main_lahan.approve == 1 &&
                _vm.data.main_lahan.fc_complete_data == null
            )?_c('span',[_vm._v("Data Bermasalah")]):(
                _vm.data.main_lahan.approve == 0 &&
                _vm.data.main_lahan.updated_gis.toLowerCase() == 'belum'
            )?_c('span',[_vm._v("Belum Diverifikasi")]):(
                _vm.data.main_lahan.approve == 0 &&
                _vm.data.main_lahan.updated_gis.toLowerCase() == 'sudah'
            )?_c('span',[_vm._v("Diverifikasi GIS")]):(_vm.data.main_lahan.approve == 1)?_c('span',[_vm._v("Diverifikasi FC")]):(_vm.data.main_lahan.approve == 2)?_c('span',[_vm._v("Terverifikasi")]):(_vm.data.main_lahan.approve == 3)?_c('span',[_vm._v("Force Majeure")]):_vm._e()])]):_vm._e(),(_vm.projectPurpose === 'non-carbon')?_c('div',{staticClass:"d-flex flex-row value"},[_c('span',{staticClass:"badge",class:{
            'bg-warning':
                _vm.data.main_lahan.approve === 0 &&
                _vm.data.main_lahan.fc_complete_data === null,
            'bg-primary':
                _vm.data.main_lahan.approve === 1 &&
                _vm.data.main_lahan.fc_complete_data !== null,
            'bg-success': _vm.data.main_lahan.approve === 2,
        }},[(
                _vm.data.main_lahan.approve === 0 &&
                _vm.data.main_lahan.fc_complete_data === null
            )?_c('span',[_vm._v("Belum Diverifikasi")]):(
                _vm.data.main_lahan.approve === 1 &&
                _vm.data.main_lahan.fc_complete_data !== null
            )?_c('span',[_vm._v("Data Lahan Terverifikasi")]):(_vm.data.main_lahan.approve === 2)?_c('span',[_vm._v("Terverifikasi")]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',{staticClass:"lahan-gis-verification"},[_c('div',{staticClass:"d-flex flex-row mb-3",staticStyle:{"justify-content":"space-between"}},[_c('h5',[_vm._v("Lahan Assestment")]),_c('button',{on:{"click":function($event){return _vm.$emit('close', true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('ValidationObserver',{ref:"lahanAssestment",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.role == 'fc-assestment')?_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Lahan memiliki batas yang jelas',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_boundary_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_boundary),callback:function ($$v) {_vm.$set(_vm.assestment, "is_boundary", $$v)},expression:"assestment.is_boundary"}})],1),(_vm.isCarbonProject)?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi batas',
              type: 'textarea',
              validation: ['required'],
            }},model:{value:(_vm.assestment.boundary_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "boundary_desc", $$v)},expression:"assestment.boundary_desc"}})],1):_vm._e(),(_vm.assestment.is_boundary == '1')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Foto Batas Lahan',
              validation:
                _vm.assestment.is_boundary == '1'
                  ? ['required']
                  : [],
              type: 'upload',
              api: 'lahans/upload.php',
              directory: 'foto-lahan',
              upload_type: 'image/*',
              setter: 'boundary_photo',
              view_data: 'boundary_photo',
              option: {
                label_hint:
                  'Klik gambar untuk memilih berkas yang akan diunggah',
                max_size: 5,
              },
            }},model:{value:(_vm.assestment.boundary_photo),callback:function ($$v) {_vm.$set(_vm.assestment, "boundary_photo", $$v)},expression:"assestment.boundary_photo"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Apakah ada sengketa berkaitan kepemilikan lahan atau penggunaan lahan?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_dispute_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_dispute),callback:function ($$v) {_vm.$set(_vm.assestment, "is_dispute", $$v)},expression:"assestment.is_dispute"}})],1),(_vm.assestment.is_dispute == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi sengketa',
              type: 'textarea',
              validation: _vm.assestment.is_dispute == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.dispute_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "dispute_desc", $$v)},expression:"assestment.dispute_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Apakah ada tanda akan melakukan kegiatan perluasan lahan?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_expanded_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_expanded),callback:function ($$v) {_vm.$set(_vm.assestment, "is_expanded", $$v)},expression:"assestment.is_expanded"}})],1),(_vm.assestment.is_expanded == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi perluasan lahan',
              type: 'textarea',
              validation: _vm.assestment.is_expanded == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.expanded_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "expanded_desc", $$v)},expression:"assestment.expanded_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Sistem pertanian dominan',
              type: 'select',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.farming_system,

                default_label: _vm.assestment.farming_system_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.farming_system),callback:function ($$v) {_vm.$set(_vm.assestment, "farming_system", $$v)},expression:"assestment.farming_system"}})],1),(_vm.assestment.farming_system == 'Lainnya')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi sistem pertanian',
              type: 'textarea',
              validation: _vm.assestment.farming_system == 'Lainnya'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.farming_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "farming_desc", $$v)},expression:"assestment.farming_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('v-row',{staticClass:"tutupan-wrapper pb-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('h4',{staticClass:"mb-2 text-success"},[_vm._v("Land Owner")]),_c('h5',{staticClass:"font-weight-normal"},[_vm._v(" Pemilik Lahan : "),_c('span',{staticClass:"text-success font-weight-bold"},[_vm._v(_vm._s(_vm.formData.farmers_name || '-'))])])]),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Pengelola Lahan',
                  type: 'text',
                  validation: [],
                }},model:{value:(_vm.assestment.land_occupant),callback:function ($$v) {_vm.$set(_vm.assestment, "land_occupant", $$v)},expression:"assestment.land_occupant"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Bukti kepemilikan lahan',
                type: 'select',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.type_owner,
                  default_label: _vm.assestment.type_ownership_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.type_ownership),callback:function ($$v) {_vm.$set(_vm.assestment, "type_ownership", $$v)},expression:"assestment.type_ownership"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Cara kontak yg disarankan',
                  type: 'select',
                  validation: ['required'],
                  option: {
                    default_options: _vm.defaultData.contact,
  
                    default_label: _vm.assestment.prefered_contact_label,
                    list_pointer: {
                      label: 'name',
                      code: 'code',
                      display: ['name'],
                    },
                  },
                }},model:{value:(_vm.assestment.prefered_contact),callback:function ($$v) {_vm.$set(_vm.assestment, "prefered_contact", $$v)},expression:"assestment.prefered_contact"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Email',
                  type: 'text',
                  validation: _vm.assestment.prefered_contact == 'Email' 
                    ? ['email', 'required']
                    : [],
                }},model:{value:(_vm.assestment.email),callback:function ($$v) {_vm.$set(_vm.assestment, "email", $$v)},expression:"assestment.email"}})],1),_c('v-col',{staticClass:"pb-5",attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Telepon',
                  type: 'text',
                  validation: _vm.assestment.prefered_contact == 'Telepon' 
                    ? ['decimal', 'required']
                    : ['decimal'],
                }},model:{value:(_vm.assestment.phone),callback:function ($$v) {_vm.$set(_vm.assestment, "phone", $$v)},expression:"assestment.phone"}})],1),_c('v-col',{staticClass:"pb-5",attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                  label: 'Whatsapp',
                  type: 'text',
                  validation: _vm.assestment.prefered_contact == 'Whatsapp' 
                    ? ['decimal', 'required']
                    : ['decimal'],
                }},model:{value:(_vm.assestment.whatsapp),callback:function ($$v) {_vm.$set(_vm.assestment, "whatsapp", $$v)},expression:"assestment.whatsapp"}})],1)],1)],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Apakah pemilik lahan tertarik menjadi bagian dari project?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_interest_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_interest),callback:function ($$v) {_vm.$set(_vm.assestment, "is_interest", $$v)},expression:"assestment.is_interest"}})],1),(_vm.assestment.is_interest == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Pernyataan Ketertarikan',
              type: 'textarea',
              validation: _vm.assestment.is_interest == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.interest_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "interest_proof", $$v)},expression:"assestment.interest_proof"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Penggunaan lahan sebelumnya',
            }},model:{value:(_vm.assestment.land_used),callback:function ($$v) {_vm.$set(_vm.assestment, "land_used", $$v)},expression:"assestment.land_used"}}),_c('h5',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.formData.lahan_type)+", "+_vm._s(_vm.formData.description)+" ("+_vm._s(_vm.formData.current_crops)+") ")])],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Bukti penggunaan lahan',
              type: 'select',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.land_used_proof,
                default_label: _vm.assestment.land_used_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.land_used),callback:function ($$v) {_vm.$set(_vm.assestment, "land_used", $$v)},expression:"assestment.land_used"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Penggunaan lahan saat ini',
              type: 'select',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.farming_system,

                default_label: _vm.assestment.land_use_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.land_use),callback:function ($$v) {_vm.$set(_vm.assestment, "land_use", $$v)},expression:"assestment.land_use"}})],1),(_vm.assestment.land_use == 'Lainnya')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi lainnya',
              type: 'textarea',
              validation: _vm.assestment.land_use == 'Lainnya'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.land_use_other),callback:function ($$v) {_vm.$set(_vm.assestment, "land_use_other", $$v)},expression:"assestment.land_use_other"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Adakah kegiatan ilegal di lahan?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_illegal_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_illegal),callback:function ($$v) {_vm.$set(_vm.assestment, "is_illegal", $$v)},expression:"assestment.is_illegal"}})],1),(_vm.assestment.is_illegal == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi kegiatan ilegal',
              type: 'textarea',
              validation: _vm.assestment.is_illegal == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.illegal_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "illegal_desc", $$v)},expression:"assestment.illegal_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Apakah lahan yg akan ditanami mengalami degradasi dan tidak ada tanaman?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_expected_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_expected),callback:function ($$v) {_vm.$set(_vm.assestment, "is_expected", $$v)},expression:"assestment.is_expected"}})],1),(_vm.assestment.is_expected == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi informasi lebih lengkap dari lahan',
              type: 'textarea',
              validation: _vm.assestment.is_expected == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.expected_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "expected_desc", $$v)},expression:"assestment.expected_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Bukti informasi lahan',
              type: 'select',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.expected_proof,
                default_label: _vm.assestment.expected_proof_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.expected_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "expected_proof", $$v)},expression:"assestment.expected_proof"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Foto Lahan',
              validation: [],
              type: 'upload',
              api: 'lahans/upload.php',
              directory: 'foto-lahan',
              upload_type: 'image/*',
              setter: 'photo1',
              view_data: 'photo1',
              option: {
                label_hint:
                  'Klik gambar untuk memilih berkas yang akan diunggah',
                max_size: 5,
              },
            }},model:{value:(_vm.formData.photo1),callback:function ($$v) {_vm.$set(_vm.formData, "photo1", $$v)},expression:"formData.photo1"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Apakah lahan pernah terjadi kegiatan pembukaan lahan dalam 10 tahun terakhir?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_deforested_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_deforested),callback:function ($$v) {_vm.$set(_vm.assestment, "is_deforested", $$v)},expression:"assestment.is_deforested"}})],1),(_vm.assestment.is_deforested == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi pembukaan lahan yang pernah terjadi',
              type: 'textarea',
              validation: _vm.assestment.is_deforested == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.deforested_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "deforested_desc", $$v)},expression:"assestment.deforested_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Apakah lahan terdapat wilayah perairan atau lahan basah?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_waterbody_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_waterbody),callback:function ($$v) {_vm.$set(_vm.assestment, "is_waterbody", $$v)},expression:"assestment.is_waterbody"}})],1),(_vm.assestment.is_waterbody == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Deskripsi wilayah perairan',
              type: 'textarea',
              validation: _vm.assestment.is_waterbody == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.waterbody_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "waterbody_desc", $$v)},expression:"assestment.waterbody_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Apakah lahan cocok untuk penerapan model yg telah ditetapkan sebelumnya?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,

                default_label: _vm.assestment.is_model_label,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.assestment.is_model),callback:function ($$v) {_vm.$set(_vm.assestment, "is_model", $$v)},expression:"assestment.is_model"}})],1),(_vm.assestment.is_model == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Model yang dipilih',
              type: 'text',
              validation: _vm.assestment.is_model == '1'
                  ? ['required']
                  : [],
            }},model:{value:(_vm.assestment.model_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "model_desc", $$v)},expression:"assestment.model_desc"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('v-row',{staticClass:"tutupan-wrapper pb-5"},[_c('v-col',{attrs:{"lg":"12"}},[_c('h4',{staticClass:"mb-2 text-success"},[_vm._v("Resiko Lahan")])]),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah di lahan pernah terjadi kebakaran?',
                type: 'select-radio',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.question_option,

                  default_label: _vm.assestment.is_fire_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.is_fire),callback:function ($$v) {_vm.$set(_vm.assestment, "is_fire", $$v)},expression:"assestment.is_fire"}})],1),(_vm.assestment.is_fire == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah ada bukti dokumentasi',
                type: 'select-radio',
                validation: [],
                option: {
                  default_options: _vm.defaultData.yes_no_option,

                  default_label: _vm.assestment.fire_doc_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.fire_doc),callback:function ($$v) {_vm.$set(_vm.assestment, "fire_doc", $$v)},expression:"assestment.fire_doc"}})],1):_vm._e(),(_vm.assestment.fire_doc == '1')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Foto Kebakaran',
                validation:
                  _vm.assestment.fire_doc == '1'
                    ? ['required']
                    : [],
                type: 'upload',
                api: 'lahans/upload.php',
                directory: 'foto-lahan',
                upload_type: 'image/*',
                setter: 'fire_photo',
                view_data: 'fire_photo',
                option: {
                  label_hint:
                    'Klik gambar untuk memilih berkas yang akan diunggah',
                  max_size: 5,
                },
              }},model:{value:(_vm.assestment.fire_photo),callback:function ($$v) {_vm.$set(_vm.assestment, "fire_photo", $$v)},expression:"assestment.fire_photo"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Asal keterangan/bukti',
                type: 'select',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.risk,
                  default_label: _vm.assestment.fire_proof_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.fire_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "fire_proof", $$v)},expression:"assestment.fire_proof"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah di lahan ada resiko terjadi kebakaran?',
                type: 'select-radio',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.question_option,

                  default_label: _vm.assestment.is_fire_risk_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.is_fire_risk),callback:function ($$v) {_vm.$set(_vm.assestment, "is_fire_risk", $$v)},expression:"assestment.is_fire_risk"}})],1),(_vm.assestment.is_fire_risk == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah ada bukti dokumentasi',
                type: 'select-radio',
                validation: [],
                option: {
                  default_options: _vm.defaultData.yes_no_option,

                  default_label: _vm.assestment.fire_risk_doc_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.fire_risk_doc),callback:function ($$v) {_vm.$set(_vm.assestment, "fire_risk_doc", $$v)},expression:"assestment.fire_risk_doc"}})],1):_vm._e(),(_vm.assestment.fire_risk_doc == '1')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Foto Area yg mudah terbakar',
                validation:
                  _vm.assestment.fire_risk_doc == '1'
                    ? ['required']
                    : [],
                type: 'upload',
                api: 'lahans/upload.php',
                directory: 'foto-lahan',
                upload_type: 'image/*',
                setter: 'fire_risk_photo',
                view_data: 'fire_risk_photo',
                option: {
                  label_hint:
                    'Klik gambar untuk memilih berkas yang akan diunggah',
                  max_size: 5,
                },
              }},model:{value:(_vm.assestment.fire_risk_photo),callback:function ($$v) {_vm.$set(_vm.assestment, "fire_risk_photo", $$v)},expression:"assestment.fire_risk_photo"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Asal keterangan/bukti',
                type: 'select',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.risk,
                  default_label: _vm.assestment.fire_risk_proof_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.fire_risk_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "fire_risk_proof", $$v)},expression:"assestment.fire_risk_proof"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah di lahan pernah terjadi banjir?',
                type: 'select-radio',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.question_option,

                  default_label: _vm.assestment.is_flood_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.is_flood),callback:function ($$v) {_vm.$set(_vm.assestment, "is_flood", $$v)},expression:"assestment.is_flood"}})],1),(_vm.assestment.is_flood == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah ada bukti dokumentasi',
                type: 'select-radio',
                validation: [],
                option: {
                  default_options: _vm.defaultData.yes_no_option,

                  default_label: _vm.assestment.flood_doc_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.flood_doc),callback:function ($$v) {_vm.$set(_vm.assestment, "flood_doc", $$v)},expression:"assestment.flood_doc"}})],1):_vm._e(),(_vm.assestment.flood_doc == '1')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Foto Banjir',
                validation:
                  _vm.assestment.fire_doc == '1'
                    ? ['required']
                    : [],
                type: 'upload',
                api: 'lahans/upload.php',
                directory: 'foto-lahan',
                upload_type: 'image/*',
                setter: 'flood_photo',
                view_data: 'flood_photo',
                option: {
                  label_hint:
                    'Klik gambar untuk memilih berkas yang akan diunggah',
                  max_size: 5,
                },
              }},model:{value:(_vm.assestment.flood_photo),callback:function ($$v) {_vm.$set(_vm.assestment, "flood_photo", $$v)},expression:"assestment.flood_photo"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Asal keterangan/bukti',
                type: 'select',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.risk,
                  default_label: _vm.assestment.flood_proof_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.flood_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "flood_proof", $$v)},expression:"assestment.flood_proof"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah di lahan ada resiko terjadi banjir?',
                type: 'select-radio',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.question_option,

                  default_label: _vm.assestment.is_flood_risk_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.is_flood_risk),callback:function ($$v) {_vm.$set(_vm.assestment, "is_flood_risk", $$v)},expression:"assestment.is_flood_risk"}})],1),(_vm.assestment.is_flood_risk == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah ada bukti dokumentasi',
                type: 'select-radio',
                validation: [],
                option: {
                  default_options: _vm.defaultData.yes_no_option,

                  default_label: _vm.assestment.flood_risk_doc_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.flood_risk_doc),callback:function ($$v) {_vm.$set(_vm.assestment, "flood_risk_doc", $$v)},expression:"assestment.flood_risk_doc"}})],1):_vm._e(),(_vm.assestment.flood_risk_doc == '1')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Foto area yg menjadi penyebab banjir',
                validation:
                  _vm.assestment.flood_risk_doc == '1'
                    ? ['required']
                    : [],
                type: 'upload',
                api: 'lahans/upload.php',
                directory: 'foto-lahan',
                upload_type: 'image/*',
                setter: 'flood_risk_photo',
                view_data: 'flood_risk_photo',
                option: {
                  label_hint:
                    'Klik gambar untuk memilih berkas yang akan diunggah',
                  max_size: 5,
                },
              }},model:{value:(_vm.assestment.flood_risk_photo),callback:function ($$v) {_vm.$set(_vm.assestment, "flood_risk_photo", $$v)},expression:"assestment.flood_risk_photo"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Asal keterangan/bukti',
                type: 'select',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.risk,
                  default_label: _vm.assestment.flood_risk_proof_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.flood_risk_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "flood_risk_proof", $$v)},expression:"assestment.flood_risk_proof"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah di lahan ada resiko erosi?',
                type: 'select-radio',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.question_option,

                  default_label: _vm.assestment.is_erosion_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.is_erosion),callback:function ($$v) {_vm.$set(_vm.assestment, "is_erosion", $$v)},expression:"assestment.is_erosion"}})],1),(_vm.assestment.is_erosion == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah ada bukti dokumentasi',
                type: 'select-radio',
                validation: [],
                option: {
                  default_options: _vm.defaultData.yes_no_option,

                  default_label: _vm.assestment.erosion_doc_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.erosion_doc),callback:function ($$v) {_vm.$set(_vm.assestment, "erosion_doc", $$v)},expression:"assestment.erosion_doc"}})],1):_vm._e(),(_vm.assestment.erosion_doc == '1')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Foto erosi',
                validation:
                  _vm.assestment.erosion_doc == '1'
                    ? ['required']
                    : [],
                type: 'upload',
                api: 'lahans/upload.php',
                directory: 'foto-lahan',
                upload_type: 'image/*',
                setter: 'erosion_photo',
                view_data: 'erosion_photo',
                option: {
                  label_hint:
                    'Klik gambar untuk memilih berkas yang akan diunggah',
                  max_size: 5,
                },
              }},model:{value:(_vm.assestment.erosion_photo),callback:function ($$v) {_vm.$set(_vm.assestment, "erosion_photo", $$v)},expression:"assestment.erosion_photo"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Asal keterangan/bukti',
                type: 'select',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.risk,
                  default_label: _vm.assestment.erosion_proof_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.erosion_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "erosion_proof", $$v)},expression:"assestment.erosion_proof"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah di lahan ada resiko lainnya?',
                type: 'select-radio',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.question_option,

                  default_label: _vm.assestment.is_risk_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.is_risk),callback:function ($$v) {_vm.$set(_vm.assestment, "is_risk", $$v)},expression:"assestment.is_risk"}})],1),(_vm.assestment.is_risk == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Deskripsi resiko lain',
                type: 'textarea',
                validation: _vm.assestment.is_risk == '1'
                    ? ['required']
                    : [],
              }},model:{value:(_vm.assestment.risk_desc),callback:function ($$v) {_vm.$set(_vm.assestment, "risk_desc", $$v)},expression:"assestment.risk_desc"}})],1):_vm._e(),(_vm.assestment.is_risk == '1')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Apakah ada bukti dokumentasi',
                type: 'select-radio',
                validation: [],
                option: {
                  default_options: _vm.defaultData.yes_no_option,

                  default_label: _vm.assestment.risk_doc_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.risk_doc),callback:function ($$v) {_vm.$set(_vm.assestment, "risk_doc", $$v)},expression:"assestment.risk_doc"}})],1):_vm._e(),(_vm.assestment.risk_doc == '1')?_c('v-col',{attrs:{"md":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Foto area yg beresiko',
                validation:
                  _vm.assestment.risk_doc == '1'
                    ? ['required']
                    : [],
                type: 'upload',
                api: 'lahans/upload.php',
                directory: 'foto-lahan',
                upload_type: 'image/*',
                setter: 'risk_photo',
                view_data: 'risk_photo',
                option: {
                  label_hint:
                    'Klik gambar untuk memilih berkas yang akan diunggah',
                  max_size: 5,
                },
              }},model:{value:(_vm.assestment.risk_photo),callback:function ($$v) {_vm.$set(_vm.assestment, "risk_photo", $$v)},expression:"assestment.risk_photo"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                label: 'Asal keterangan/bukti',
                type: 'select',
                validation: ['required'],
                option: {
                  default_options: _vm.defaultData.risk,
                  default_label: _vm.assestment.risk_proof_label,
                  list_pointer: {
                    label: 'name',
                    code: 'code',
                    display: ['name'],
                  },
                },
              }},model:{value:(_vm.assestment.risk_proof),callback:function ($$v) {_vm.$set(_vm.assestment, "risk_proof", $$v)},expression:"assestment.risk_proof"}})],1)],1)],1),_vm._l((_vm.questions),function(question,i){return _c('v-col',{key:`quest-${i}`,attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: question.question + '?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.formData[`question_${question.id}`]),callback:function ($$v) {_vm.$set(_vm.formData, `question_${question.id}`, $$v)},expression:"formData[`question_${question.id}`]"}})],1)}),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
            label: 'Eligibilitas Lahan',
            type: 'select-radio',
            validation: ['required'],
            option: {
              default_options: [
                {
                  label: 'Tidak Bisa Ikut',
                  code: '0',
                },
                {
                  label: 'Bisa Ikut Dengan Kondisi',
                  code: '1',
                },
                {
                  label: 'Bisa Ikut',
                  code: '2',
                },
              ],
              default_label: _vm.formData.eligible_status_label,
              list_pointer: {
                label: 'name',
                code: 'code',
                display: ['name'],
              },
            },
          }},model:{value:(_vm.formData.eligible_status),callback:function ($$v) {_vm.$set(_vm.formData, "eligible_status", $$v)},expression:"formData.eligible_status"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"type":"submit","variant":"warning","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-content-save")]),_c('span',[_vm._v("Simpan Data")])],1)],1)],2):_vm._e()],1)]}}],null,false,4058825637)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
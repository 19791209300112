var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data.main_lahan)?_c('div',{staticClass:"d-flex flex-col log-data"},[_c('div',{staticClass:"log-data-item active"},[_vm._m(0),_c('div',{staticClass:"log-value"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.created_at, "D MMMM YYYY HH:mm" )))]),_c('span',{staticClass:"label"},[_vm._v("FF Menambahkan Data Lahan")]),_c('span',{staticClass:"user"},[_vm._v("FF: "+_vm._s(_vm.data.main_lahan.field_facilitators_name))])])]),_c('div',{staticClass:"log-data-item",class:{
        active: [0, 1].includes(_vm.data.main_lahan.fc_complete_data),
    }},[_vm._m(1),_c('div',{staticClass:"log-value"},[(_vm.data.main_lahan.fc_complete_data_at)?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.fc_complete_data_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("FC Verifikasi Kelengkapan Data")]),(_vm.data.main_lahan.fc_complete_data_by)?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.fc_complete_data_by))]):_vm._e()])]),(_vm.projectPurpose === 'carbon')?_c('div',{staticClass:"log-data-item",class:{
        active: _vm.data.main_lahan.gis_updated_at,
    }},[_vm._m(2),_c('div',{staticClass:"log-value"},[(_vm.data.main_lahan.gis_updated_at)?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.gis_updated_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("GIS Verifikasi Polygon & Data Lahan")]),(_vm.data.main_lahan.gis_updated_at)?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.gis_officer))]):_vm._e()])]):_vm._e(),(_vm.projectPurpose === 'carbon')?_c('div',{staticClass:"log-data-item",class:{
        active: _vm.data.main_lahan.polygon_tutupan_status,
    }},[_vm._m(3),_c('div',{staticClass:"log-value"},[(_vm.data.main_lahan.gis_update_eligibility_at)?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.gis_update_eligibility_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("GIS Verifikasi Polygon Tutupan & Eligibilitas")])])]):_vm._e(),_c('div',{staticClass:"log-data-item",class:{
        active: _vm.data.main_lahan.approve >= 1,
    }},[_vm._m(4),_c('div',{staticClass:"log-value"},[(
                _vm.data.main_lahan.approve >= 1 &&
                _vm.data.main_lahan.approved_at
            )?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.approved_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("FC Verifikasi Data Lahan")]),(
                _vm.data.main_lahan.approve >= 1 &&
                _vm.data.main_lahan.approved_by
            )?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.approved_by))]):_vm._e()])]),(_vm.projectPurpose === 'carbon')?_c('div',{staticClass:"log-data-item",class:{
        active: _vm.data.main_lahan.approve == 2,
    }},[_vm._m(5),_c('div',{staticClass:"log-value"},[(
                _vm.data.main_lahan.approve == 2 &&
                _vm.data.main_lahan.update_eligible_at
            )?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.update_eligible_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("UM Menentukan Eligibilitas Lahan")]),(
                _vm.data.main_lahan.approve == 2 &&
                _vm.data.main_lahan.update_eligible_by
            )?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.update_eligible_by))]):_vm._e()])]):_vm._e(),(_vm.projectPurpose === 'non-carbon')?_c('div',{staticClass:"log-data-item",class:{
        active: _vm.data.main_lahan.approve == 2,
    }},[_vm._m(6),_c('div',{staticClass:"log-value"},[(
                _vm.data.main_lahan.approve == 2 &&
                _vm.data.main_lahan.approved_at
            )?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.approved_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("UM Verifikasi Data Lahan")]),(
                _vm.data.main_lahan.approve == 2 &&
                _vm.data.main_lahan.approved_by
            )?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.approved_by))]):_vm._e()])]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})])
}]

export { render, staticRenderFns }
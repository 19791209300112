var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"850","scrollable":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-row justify-content-between mb-5"},[(_vm.openFormUpload)?_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.formUploadPreview ? "Preview" : "Upload")+" Lampiran ")]):_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v("Preview MOU")]),(!_vm.openFormUpload)?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"d-flex flex-row align-items-center",attrs:{"small":"","variant":"primary"},on:{"click":_vm.onPrint}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('v-icon',[_vm._v("mdi-printer-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Print "+_vm._s(_vm.mouData && _vm.mouData.printed_status ? "Ulang" : "")+" MOU")])],1),(
            (_vm.mouData && _vm.mouData.mou_status == 0) || (_vm.mouData && !_vm.mouData.id)
          )?_c('v-btn',{staticClass:"d-flex flex-row align-items-center ml-2",attrs:{"small":"","variant":"warning"},on:{"click":() => (_vm.dialogs.revision.isOpen = true)}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"warning"}}):_c('v-icon',[_vm._v("mdi-file-edit-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Revisi MOU")])],1):_vm._e(),(
            _vm.mouData && _vm.mouData.printed_status && _vm.mouData.mou_status != 5
          )?_c('v-btn',{staticClass:"d-flex flex-row align-items-center ml-2",attrs:{"disabled":_vm.loading,"small":"","variant":"warning"},on:{"click":function($event){_vm.openFormUpload = true;
          _vm.formUploadPreview = false;}}},[_c('v-icon',[_vm._v("mdi-file-alert-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Upload Lampiran")])],1):_vm._e(),(
            _vm.mouData &&
            _vm.mouData.printed_status &&
            [4, 5].includes(_vm.mouData.mou_status)
          )?_c('v-btn',{staticClass:"d-flex flex-row align-items-center ml-2",attrs:{"disabled":_vm.loading,"small":"","variant":"success"},on:{"click":function($event){_vm.openFormUpload = true;
          _vm.formUploadPreview = true;}}},[_c('v-icon',[_vm._v("mdi-file-eye-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Preview Lampiran")])],1):_vm._e()],1):_vm._e()]),_c('v-card-text',{staticClass:"pt-5"},[(_vm.mouData && _vm.mouData.printed_at && !_vm.openFormUpload)?_c('v-alert',{attrs:{"dense":"","text":"","icon":"mdi-printer-check","type":"info"}},[_vm._v(" "+_vm._s(_vm._f("parse")(_vm.mouData.printed_at,"datetime"))+" ")]):_vm._e(),(_vm.mouData && _vm.mouData.mou_status == 2)?_c('v-alert',{attrs:{"dense":"","text":"","icon":"mdi-alert-circle-outline","type":"warning"}},[_vm._v(" Revisi: "+_vm._s(_vm.mouData.mou_revision_reason)+" ")]):_vm._e(),(_vm.openFormUpload)?_c('FormUploadAttachmentMOU',{attrs:{"mouData":_vm.mouData,"lahanData":_vm.lahanData,"preview":_vm.formUploadPreview},on:{"close":function($event){_vm.openFormUpload = false},"closeParent":function($event){_vm.isOpen = false},"refreshData":function($event){return _vm.refreshDetailLahan()},"openPrintAppendix":_vm.openPrintAppendix}}):(typeof _vm.data === 'object' && Object.keys(_vm.data).length > 0)?_c('div',{staticClass:"preview-wrapper"},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":true,"preview-modal":true,"pdf-quality":1,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"794px","pdf-content-height":1123,"html-to-pdf-options":{
              margin: 0,
              filename: `MOU-${_vm.data.farmer_name}`,
              pagebreak: {
                mode: ['css', 'legacy'],
                after: '.html2pdf__page-break',
              },
              html2canvas: {
                scale: 3,
                useCORS: true,
              },
              jsPDF: {
                format: 'a4',
                unit: 'mm',
                orientation: 'p',
              },
            }},on:{"beforeDownload":function($event){return _vm.beforeDownload($event)},"hasDownloaded":function($event){return _vm.onDownloadComplete()}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('mou-html',{attrs:{"data":_vm.data}})],1)])],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogs.revision.isOpen),callback:function ($$v) {_vm.$set(_vm.dialogs.revision, "isOpen", $$v)},expression:"dialogs.revision.isOpen"}},[_c('v-card',[_c('v-card-title',[_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v("Confirm Revision")])]),_c('v-card-text',[_c('ValidationObserver',{ref:"revisionForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmitRevisi)}}},[_c('geko-input',{staticClass:"mb-5",attrs:{"item":{
              label: 'Deskripsi Revisi',
              validation: ['required'],
              type: 'textarea',
            }},model:{value:(_vm.dialogs.revision.data.mou_revision_reason),callback:function ($$v) {_vm.$set(_vm.dialogs.revision.data, "mou_revision_reason", $$v)},expression:"dialogs.revision.data.mou_revision_reason"}}),_c('v-btn',{attrs:{"type":"submit","variant":"success","disabled":_vm.loading}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"success"}}):_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" Submit Revisi ")],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
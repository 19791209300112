var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',{staticClass:"lahan-gis-verification"},[_c('div',{staticClass:"d-flex flex-row mb-3",staticStyle:{"justify-content":"space-between"}},[_c('h5',[_vm._v("Verifikasi Data")]),_c('button',{on:{"click":function($event){return _vm.$emit('close', true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('ValidationObserver',{ref:"fcCompleteDataForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[(_vm.role == 'fc-verif-data')?_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: 'Kelengkapan Data',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: [
                  {
                    label: 'Lengkap',
                    code: '1',
                  },
                  {
                    label: 'Tidak Lengkap',
                    code: '0',
                  },
                ],
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.formData.fc_complete_data),callback:function ($$v) {_vm.$set(_vm.formData, "fc_complete_data", $$v)},expression:"formData.fc_complete_data"}})],1):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"type":"submit","variant":"warning","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v("Verifikasi Data")])],1)],1)],1)],1)]}}],null,false,1275150734)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"1200","scrollable":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-row justify-content-between mb-5"},[_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v("Preview Appendix Lahan")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"d-flex flex-row align-items-center",attrs:{"largew":"","variant":"primary"},on:{"click":_vm.onPrint}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"primary"}}):_c('v-icon',[_vm._v("mdi-printer-outline")]),_c('span',{staticClass:"ms-1"},[_vm._v("Print Appendix")])],1)],1)]),_c('v-card-text',{staticClass:"pt-5"},[_c('div',{staticClass:"preview-wrapper"},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":true,"preview-modal":true,"filename":`MOU-${_vm.data.farmer_name}-${_vm.data.mou_start}-${_vm.data.mou_end}`,"pdf-quality":1,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"landscape","pdf-content-width":"1123px","pdf-content-height":"794px","html-to-pdf-options":{
                margin: 0,
                pagebreak: {
                  mode: ['css', 'legacy'],
                  after: '.html2pdf__page-break',
                },
                html2canvas: {
                  scale: 3,
                  useCORS: true,
                },
                jsPDF: {
                  format: 'a4',
                  unit: 'mm',
                  orientation: 'l',
                },
              }},on:{"hasDownloaded":function($event){return _vm.onDownloadComplete()}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('appendix-html',{attrs:{"data":_vm.data}})],1)])],1)])],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lahan-gis-verification"},[_c('div',{staticClass:"d-flex flex-row mb-3",staticStyle:{"justify-content":"space-between"}},[_c('p',[_vm._v("Legal Land Category: "),_c('br'),_c('v-btn',{staticClass:"px-5",attrs:{"variant":"info"}},[_c('b',[_vm._v(_vm._s(_vm.lahanData.farmers_legal_land_categories_pivot_farmer))])])],1),_c('v-btn',{attrs:{"small":"","variant":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-file-eye-outline")]),_c('span',[_vm._v("Lihat MOU")])],1)],1),(_vm.mouData.mou_status > 2)?_c('div',[(!_vm.preview)?_c('geko-input',{attrs:{"item":{
        label: _vm.formInputs.find(v => v.field == 'mou_file').label,
        validation: _vm.formInputs.find(v => v.field == 'mou_file').validation,
        type: _vm.formInputs.find(v => v.field == 'mou_file').type,
        setter: _vm.formInputs.find(v => v.field == 'mou_file').setter,
        view_data: `${_vm.iLahan}${_vm.i}${_vm.formInputs.find(v => v.field == 'mou_file').view_data}`,
        api: _vm.formInputs.find(v => v.field == 'mou_file').api,
        upload_type: _vm.formInputs.find(v => v.field == 'mou_file').upload_type,
        directory: _vm.formInputs.find(v => v.field == 'mou_file').directory,
        option: {
          icon: 'mdi-file-outline',
          label_hint:
            'Klik untuk memilih file yang akan diunggah',
        },
      }},model:{value:(_vm.mouData.mou_file),callback:function ($$v) {_vm.$set(_vm.mouData, "mou_file", $$v)},expression:"mouData.mou_file"}}):_c('div',{staticStyle:{"width":"250px"},style:({
      backgroundImage:
        'url(' +
        _vm.$_config.baseUrlUpload +
        '/' +
        _vm.mouData.mou_file +
        ')',
      }),attrs:{"id":"preview-wrapper"},on:{"click":function($event){return _vm.showLightbox(
        _vm.$_config.baseUrlUpload + '/' + _vm.mouData.mou_file
      )}}},[_c('h5',[_vm._v(_vm._s(_vm.formInputs.find(v => v.field == 'mou_file').label))])]),_c('v-tabs',{staticClass:"mt-5",attrs:{"background-color":"transparent","color":"success"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.mouData.mou_lahans),function(lahan,iLahan){return _c('v-tab',{key:`tab${iLahan}`},[_c('b',[_vm._v(_vm._s(lahan.lahan_no))])])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.mouData.mou_lahans),function(lahan,iLahan){return _c('v-tab-item',{key:`tab${iLahan}`},[_c('v-card',{attrs:{"color":"basil","flat":""}},[_c('v-card-text',[_c('ValidationObserver',{ref:`formUploadAttachment${iLahan}`,refInFor:true,scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_vm._l((_vm.formInputs),function(input,i){return [(input.show)?_c('v-col',{key:`formInputAttach${iLahan}${i}`,attrs:{"lg":_vm.preview ? 4 : 6}},[(!_vm.preview)?_c('geko-input',{attrs:{"item":{
                          label: input.label,
                          validation: input.validation,
                          type: input.type,
                          setter: input.setter,
                          view_data: `${iLahan}${i}${input.view_data}`,
                          api: input.api,
                          upload_type: input.upload_type,
                          directory: input.directory,
                          option: {
                            icon: 'mdi-file-outline',
                            label_hint:
                              'Klik untuk memilih file yang akan diunggah',
                          },
                        }},model:{value:(lahan[input.field]),callback:function ($$v) {_vm.$set(lahan, input.field, $$v)},expression:"lahan[input.field]"}}):_c('div',{style:({
                        backgroundImage:
                          'url(' +
                          _vm.$_config.baseUrlUpload +
                          '/' +
                          lahan[input.field] +
                          ')',
                        }),attrs:{"id":"preview-wrapper"},on:{"click":function($event){return _vm.showLightbox(
                          _vm.$_config.baseUrlUpload + '/' + lahan[input.field]
                        )}}},[_c('h5',[_vm._v(_vm._s(input.label))])])],1):_vm._e()]}),_c('v-col',{attrs:{"lg":"12"}},[(!_vm.preview)?_c('v-btn',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"type":"submit","variant":"success","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_c('span',[_vm._v("Simpan")])],1):_vm._e()],1)],2)],1)]}}],null,true)})],1)],1)],1)}),1),(_vm.preview)?_c('v-btn',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"type":"button","variant":"success","disabled":_vm.loading || _vm.mouData.mou_status == 5},on:{"click":function($event){return _vm.lastVerification()}}},[_c('v-icon',[_vm._v("mdi-check-decagram-outline")]),_c('span',[_vm._v(_vm._s(_vm.mouData.mou_status == 5 ? 'Sudah' : '')+"Verifikasi Data")])],1):_vm._e()],1):_c('div',{staticClass:"my-5 py-5"},[_c('v-alert',{attrs:{"color":"warning","dense":"","text":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"warning"}},[_vm._v("mdi-printer-alert")]),_vm._v(" Silakan Print Appendix Terlebih Dahulu! ")],1),_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.openPrintAppendix()}}},[_vm._v(" Print Appendix ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
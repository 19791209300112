var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticStyle:{"border-right":"1px solid transparent"}},[_c('section',{staticClass:"pdf-item",staticStyle:{"padding":"2em"}},[_c('h3',{staticClass:"text-center text-black font-weight-bold text-1-em",staticStyle:{"margin-bottom":"2em"}},[_vm._v(" Appendix Lahan"),_c('br'),_vm._v("MOU "+_vm._s(_vm.data.mou_no)+" ")]),_c('div',{staticClass:"wrapper",staticStyle:{"display":"flex","flex-direction":"row","align-items":"flex-start","height":"35em"}},[_c('div',{style:({
          'background-image': `url(${_vm.data.polygon_image})`,
          width: '65%',
          height: '100%',
          'background-size': 'cover',
          'background-position': 'center',
          'border-radius': '1em',
        })}),_c('div',{staticStyle:{"width":"35%","margin-left":"2em","height":"100%","display":"flex","flex-direction":"column","justify-content":"space-between","min-height":"100%","border":"1px solid rgba(128, 128, 128, 0.2)"}},[_c('div',{staticStyle:{"width":"100%","padding":"1.5em"}},[_c('h3',[_vm._v("Identitas Petani")]),_c('table',{staticClass:"farmer"},[_c('tr',[_c('td',[_vm._v("Nama")]),_c('td',[_vm._v(_vm._s(_vm.data.farmer_name)+" | "+_vm._s(_vm.data.farmer_no))])]),_c('tr',[_c('td',[_vm._v("Luas")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parse")(_vm.data.gis_polygon_area,"ts"))+" m² (Luas Tanam : "+_vm._s(_vm._f("parse")(_vm.data.gis_planting_area,"ts"))+" m²) ")])]),_c('tr',[_c('td',[_vm._v("Luas Area Enhancement ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parse")(_vm.data.gis_planting_enhancement_area,"ts"))+" m² ")])]),_c('tr',[_c('td',[_vm._v("Eligibility Status ")]),_c('td',[_vm._v(" "+_vm._s(_vm.GISEligibilityStatus(_vm.data.gis_eligibility_type))+" ")])]),_c('tr',[_c('td',[_vm._v("Model")]),_c('td',[_vm._v(_vm._s(_vm.data.farmers_project_model ?? ''))])])]),_c('h3',{staticStyle:{"margin-top":"1em"}},[_vm._v("Jenis & Jumlah Pohon")]),_c('table',{staticClass:"tree"},_vm._l((Array.isArray(_vm.data.trees) ? _vm.data.trees : []),function(tree,i){return _c('tr',[_c('td',[_vm._v(_vm._s(tree.trees_tree_name))]),_c('td',[_vm._v(_vm._s(_vm._f("parse")(tree.amount,"ts"))+" pohon")])])}),0)]),_vm._m(0)])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","background-color":"#f8f8f8","display":"flex","flex-direction":"row","align-items":"center"}},[_c('img',{staticStyle:{"width":"20%"},attrs:{"src":"/images/tft_logo.png"}}),_c('div',{staticStyle:{"padding-inline":"1em"}},[_c('p',{staticStyle:{"margin-bottom":"0.1em"}},[_vm._v("Sumber Data :")]),_c('p',{staticStyle:{"margin-bottom":"0","font-size":"0.9em"}},[_vm._v(" - Google Earth Satellite ")]),_c('p',{staticStyle:{"margin-bottom":"0","font-size":"0.9em"}},[_vm._v(" - Survey Pengukuran Lahan 2024 ")])])])
}]

export { render, staticRenderFns }